<template>
  <div v-editable="blok">
    <component :key="blok._uid" v-for="blok in blok.body" :blok="blok" :is="blok.component | dashify"></component>

    <section>
      <v-container>
        <v-row>
          <v-col cols="12" sm="5" md="4" lg="3">
            <Menu/>
          </v-col>
          <v-col cols="12" sm="7" md="8" lg="9" class="overview">
            <v-row>
              <v-col cols="12" v-if="blok.title || blok.text">
                <h1 v-if="blok.title">{{ blok.title }}</h1>
                <p v-if="total > 0" class="text-orange">
                  {{currentItemsText}} van de {{filteredTotal}} toestellen (totaal {{total}})
                </p>
                <div
                  v-if="blok.text"
                  v-html="$md.render(blok.text)"
                  :class="{'mt-4': blok.title}"
                ></div>
              </v-col>

              <v-col cols="12" ref="filters">
                <v-row>
                  <v-col
                    v-for="(filter, key) in filters"
                    :key="`filter-${key}`"
                  >
                    <v-select
                      clearable
                      hide-details
                      filled
                      v-model="selectedFilters[key]"
                      @input="setFilters()"
                      no-data-text="Geen filteropties"
                      :multiple="filter.multiple"
                      :label="filter.label"
                      :items="filter.items"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12" v-if="failedLoadingItems">
                <v-alert type="error">
                  Er is iets fout gegaan, de producten konden niet worden geladen. Probeer het a.u.b. later opnieuw.
                </v-alert>
              </v-col>

              <template
                v-else
                v-for="item in currentItems"
              >
                <v-col
                  cols="12" sm="6" md="4"
                  v-if="item.active"
                  :key="item.uuid"
                >
                  <product-item :item="item" :uid="item.uuid"/>
                </v-col>
              </template>
            </v-row>

            <v-divider v-if="pages > 1"/>

            <v-pagination
              v-model="page"
              v-if="pages > 1"
              :length="pages"
              :total-visible="10"
              v-on:input="scrollToTop"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <invoice/>
  </div>
</template>

<script>
  import _ from 'lodash'
  const VueScrollTo = require('vue-scrollto')
	import Menu from '@/components/pages/ProductCategoryOverview/Menu'
  import ProductItem from '@/components/pages/ProductOverview/Product'
	import Invoice from '~/components/Invoice'
  const filterKeys = []
  const filters = {}
  const perPage = 30;

  _.each(require('@/assets/js/filters'), (filter, filterKey) => {
  	filterKeys.push(filterKey)

  	filters[filterKey] = (Object.assign(filter, {
  		items: _.map(filter.values, function(value, key) {
			  return {
          text: value,
          value: key
        };
		  })
    }))
  })

	export default {
		name: 'ProductOverviewPage',
		props: ['blok'],
    components: { Menu, ProductItem, Invoice },
		data() {
			return {
				items: [],
        currentItems: null,
        filters: _.cloneDeep(filters),
        usableFilters: {},
        selectedFilters: {},
        failedLoadingItems: false,
				itemsLoaded: false,
        perPage,
        total: 0,
        filteredTotal: 0,
        pages: 0,
				page: parseInt(this.$route.query.page) || 1
			}
		},
    computed: {
	    favorites() {
		    return this.$store.state.favorites
	    },
      currentItemsText() {
	    	const from = (this.page === 1 ? 1 : ((this.page - 1) * this.perPage) + 1);
	    	let till = (this.page === 1 ? this.perPage : this.page * this.perPage);

	    	if (till > this.filteredTotal) {
	    		till = this.filteredTotal
        }

	      return `${from} - ${till}`
      }
    },
    watch: {
			page(page) {
				if (this.page > 1 || this.$route.query.page) {
					this.$router.push({query: {page: page}})
				}
				this.itemsLoaded = true;
        this.setCurrentItems()
      },
	    '$route'(to, from) {
        // noinspection EqualityComparisonWithCoercionJS
		    if (to.query.page != from.query.page) {
		    	this.page = parseInt(to.query.page)
        }
	    }
    },
		mounted() {
			if (!this.itemsLoaded) {
				this.itemsLoaded = true;
				this.setCurrentItems()
			}
		},
		methods: {
			async setCurrentItems () {
				if (!this.currentItems) {
					await this.loadItems()
				}

				const items = _.filter(_.cloneDeep(this.items), item => item.active)

        this.filteredTotal = Object.keys(items).length
				this.pages = Math.ceil(this.filteredTotal / perPage)

        if (this.page > this.pages) {
	        this.$router.push({query: {page: '1'}})
        }

				this.currentItems = items.slice(
					(this.page === 1 ? 0 : (this.page - 1) * perPage), (this.page === 1 ? perPage : this.page * perPage)
        )
			},
			async loadItems() {
				let stories = [];

				try {
					let done = false;
					let total = 0;
					let page = 1;

					do {
						const {data, headers} = await this.$storyapi.get('cdn/stories', {
							'filter_query[component][in]': 'page_product',
							'story_only': true,
							'is_published': true,
							'per_page': 100,
							'page': page++,
							'is_start_page': 0,
							'starts_with': this.$attrs.full_slug,
							'sort_by': 'position:DESC',
						});

						total += data.stories.length;

						data.stories.forEach((story) => {
							let image = null;
							if ((story.content.images).length) {
								image = story.content.images[0].image
							}
							else if ((story.content.renders).length) {
								image = story.content.renders[0].image
							}
							else if ((story.content.blueprints).length) {
								image = story.content.blueprints[0].image
							}

							const filterOptions = {};
							_.each(filterKeys, (key) => {
								if (story.content[key]) {
									filterOptions[key] = story.content[key];
									if (!this.usableFilters[key]) {this.usableFilters[key] = new Set()}
									if (typeof story.content[key] === 'object') {
										(story.content[key]).forEach(item => this.usableFilters[key].add(item))
									} else if (typeof story.content[key] !== 'undefined') {
										this.usableFilters[key].add(story.content[key])
									}
								}
							});

							stories.push({
                uuid: story.uuid,
								active: true,
								slug: story.full_slug,
								title: story.content.name,
								productLine: story.content.productLine,
								articleNumber: story.content.articleNumber,
								favorite: this.favorites.includes(story.uuid),
								image,
								filterOptions
							});
						});

						done = !headers.total || total >= headers.total;

						this.total = total;
					} while (!done);
				} catch {
					this.failedLoadingItems = true
				}

				this.setUsableFilters();

				this.items = stories;
			},
      setUsableFilters() {
				let usableFilters = [];

        if (_.filter(this.selectedFilters, _.size).length === 0) {
        	usableFilters = _.cloneDeep(this.usableFilters)
        } else {
          _.each(this.items, story => {
          	if (story.active) {
              _.each(filterKeys, (key) => {
                if (!usableFilters[key]) {usableFilters[key] = new Set()}
                if (typeof story.filterOptions[key] === 'object') {
                  (story.filterOptions[key]).forEach(item => usableFilters[key].add(item))
                } else if (typeof story.filterOptions[key] !== 'undefined') {
                  usableFilters[key].add(story.filterOptions[key])
                }
              })
            }
          });
        }

	      // Remove filter options that won't have effect
	      this.filters = _.each(_.cloneDeep(filters), ((filter, key) => {
		      filter.items = _.filter(filter.items, (
		      	item => usableFilters[key] ? usableFilters[key] === item.value || usableFilters[key].has(item.value) : false)
          )
	      }));
      },
      setFilters() {
        _.each(this.items, item => {
        	let active = true;

        	_.each(filterKeys, key => {
        		if (key === 'productLine' || key === 'fallingHeightGroup') {
              if (
              	this.selectedFilters[key] && this.selectedFilters[key].length &&
                !(this.selectedFilters[key]).includes(item.filterOptions[key])
              ) {
              	active = false
              }
            }
            else if (_.difference(this.selectedFilters[key], item.filterOptions[key]).length !== 0) {
            	active = false
            }
          });

        	item.active = active;
        });

        this.setCurrentItems()
        this.setUsableFilters()
      },
      scrollToTop() {
	      VueScrollTo.scrollTo(this.$refs.filters, {offset: -80});
      }
		}
	}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  .text-orange {
    color: map-get($brand-colors, 'orange');
  }

  section {
    > .container {
      max-width: $max-site-width;
    }
  }

  .overview {
    margin-top: -$gutter-width;

    ::v-deep .product {
      margin-top: $gutter-width;
    }
  }
</style>
