<template>
  <section class="navigation" v-editable="blok">
    <v-container>
      <v-row justify="center">
        <v-col
          v-for="(button, i) in blok.buttons"
          :key="i"
          cols="6" sm="4" lg="2"
        >
          <app-link
            v-if="is_link(button.to)"
            :to="button.to | url"
          >
            <v-icon v-if="button.icon">icon-{{button.icon}}</v-icon>
            <span>{{ button.text }}</span>
          </app-link>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
	import AppLink from '~/components/AppLink';

	export default {
		name: 'NavigationBlok',
		props: ['blok'],
		components: {AppLink},
	}
</script>

<style lang="scss" scoped>
  @import '~assets/scss/variables';

  a {
    border: none;
    outline: none;
    border-radius: 2rem;
    background: map-get($grey, 'lighten-4');
    padding: 0 1rem;

    text: {
      decoration: none;
      align: center;
    }
    color: $text-color;
    font: {
      weight: $font-weight-bold;
      size: .9rem;
    }

    transition: box-shadow .1s, color .2s;

    display: flex;
    align-items: center;

    > span {
      margin-top: 1px;
    }

    > .v-icon {
      font-size: 2.5rem;
      padding-right: 1rem;
    }

    &:active {
      box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    }

    &:hover {
      color: map-get($brand-colors, 'blue');

      > .v-icon {
        color: map-get($brand-colors, 'blue');
      }
    }
  }
</style>
