<template>
	<section
		:data-src="blok.image"
		:data-lowsrc="blok.image | resize('100x0')"
		ref="banner"
		v-editable="blok"
		v-if="blok.image"
    :class="`banner${blok.color ? ` ${blok.color}` : ''}`"
	>
		<div class="preloader"></div>
		<banner-body :blok="blok"/>
	</section>
	<section
    :class="`banner${blok.color ? ` ${blok.color}` : ''}`"
		v-editable="blok"
		v-else
	>
		<banner-body :blok="blok"/>
	</section>
</template>

<script>
	import _ from 'lodash';
	import BannerBody from '~/components/BannerBody';

	export default {
		name: 'Banner',
		props: ['blok'],
		components: { BannerBody },
		mounted() {
			const that = this;
			const $banner = this.$refs.banner;

			if ($banner !== undefined)
			{
				if ($banner.dataset.src) {
					const img = new Image();

					const $preloader = $banner.querySelector('.preloader');
					$preloader.style.backgroundImage = `url(${$banner.dataset.lowsrc})`;

					const sizes = [720, 1280, 1600, 2000];
					const width = _.find(sizes, function (x) { return (window.innerWidth * window.devicePixelRatio) <= x });

					img.src = width ? that.$options.filters.resize($banner.dataset.src, `${width}x0`) : $banner.dataset.src;
					img.onload = function() {
						$banner.style.backgroundImage = 'url(' + img.src + ')';
						$preloader.classList.add('img-loaded');
					};
				}
				else
				{
					$banner.remove();
				}
			}
		}
	}
</script>
