<template>
	<AppLink :to="to" class="highlight-item">
		<v-img
			v-if="item.image"
			itemprop="image"
			:src="item.image | resize('700x0')"
			:lazy-src="item.image | resize('100x0')"
			:srcset="srcset(item.image)"
			sizes="auto"
		>
			<template slot="placeholder">
				<v-row class="fill-height ma-0"
				       align="center"
				       justify="center"
				>
					<v-progress-circular indeterminate color="#60af40"></v-progress-circular>
				</v-row>
			</template>
		</v-img>
    <p v-if="item.title" class="hidden-xs-only">
      <span
        v-if="item.bulletColor && item.bulletColor !== 'none'"
        :class="item.bulletColor ? `bullet-${item.bulletColor}` : null"
      ></span>
      {{ item.title }}
    </p>
	</AppLink>
</template>

<script>
	import AppLink from '~/components/util/AppLink'

	export default {
		name: 'Highlight',
		props: ['item'],
		components: {AppLink},
		methods: {
			srcset(image, ratio) {
				const that = this;
				let src = '';

				[300, 600, 900, 1200, 1600].forEach((width) => {
					src += `${that.$options.filters.resize(image, `${width}x${ratio ? Math.round(width / ratio) : 0}`)} ${width}w, `;
				});

				src = src.slice(0, -2);

				return src;
			}
		},
		computed: {
			to() {
				return this.$options.filters.is_link(this.item.link) ? this.$options.filters.url(this.item.link) : false
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/_variables.scss";

	.highlight-item {
    position: relative;

    @at-root a {
      overflow: hidden;

      &:before,
      &:after {
        content: '';
        position: absolute;
        opacity: 0;
      }

      &:before {
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: map-get($brand-colors, 'green');
        transition: opacity .2s;
      }

      &:hover {
        &:before {
          opacity: .4;
        }
      }
    }

    > p {
      position: absolute;
      z-index: 5;

      bottom: 1rem;
      left: 1rem;
      right: 1rem;

      color: #fff;
      text-align: left;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
      font-weight: $font-weight-bold;

      backface-visibility: hidden;

      @media (min-width: map-get($grid-breakpoints, 'md')) {
        font-size: 1.5rem;
      }

      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        font-size: 1.8rem;
      }

      > span {
        display: inline-block;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;

        &.bullet-green {
          background: map-get($brand-colors, 'green')
        }

        &.bullet-orange {
          background: map-get($brand-colors, 'orange')
        }

        &.bullet-yellow {
          background: map-get($brand-colors, 'yellow')
        }

        &.bullet-blue {
          background: map-get($brand-colors, 'boring-blue')
        }

        &.bullet-blue-grey {
          background: map-get($brand-colors, 'blue-grey')
        }

        &.bullet-brown {
          background: map-get($brand-colors, 'brown')
        }
      }
    }
  }
</style>
