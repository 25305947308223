<template>
  <div class="off-canvas-menu-item">
    <div class="inner">
      <div class="prepend">
        <app-link
         v-if="is_link(blok.link)"
         :to="blok.link | url"
        >
          {{ blok.name }}
        </app-link>
        <span v-else>
          {{ blok.name }}
        </span>
      </div>
      <div
        v-if="children.length"
        class="append"
      >
        <v-btn
          text
          icon
          @click="isOpen = true"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
    <v-slide-x-reverse-transition v-if="children.length">
      <div
        v-show="isOpen"
        class="list"
      >
        <div class="header">
          <div class="prepend">
            <v-btn
              text
              icon
              @click="isOpen = false"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </div>
          <div class="heading">
            <span>{{ blok.name }}</span>
          </div>
        </div>
        <div
          ref="overflow"
          class="overflow"
        >
          <off-canvas-menu-item
            v-for="item in children"
            :key="item._uid"
            :blok="item"
          />
        </div>
      </div>
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
  import AppLink from '@/components/util/AppLink'

	export default {
		name: 'OffCanvasMenuItem',
    components: {AppLink},
		props: {
			blok: {
				type: Object,
				required: true
			}
		},

		data: () => ({
			isOpen: false
		}),

		computed: {
			children() {
				return this.blok.children || [];
			},
		},

		watch: {
			$route() {
				if (!this.$vuetify.breakpoint.xsOnly) {return;}

				this.isOpen = this.active;
			}
		},

		mounted() {
			this.isOpen = this.active;
		}
	}
</script>

<style lang="scss" scoped>
  @import '~assets/scss/variables.scss';

  $btn-size: 36px;

  .off-canvas-menu-item {
    padding: .25rem .75rem;
    background-color: $offcanvas-menu-background;

    color: $offcanvas-menu-color;
    font: {
      weight: $offcanvas-menu-font-weight;
      family: $offcanvas-menu-font-family;
    }

    border: solid rgba($offcanvas-menu-color, .2);
    border-width: 0 0 1px 0;

    &:first-child {
      border-width: 1px 0;
    }

    &.active,
    &.exact-active {
      background-color: $offcanvas-menu-active-color;
      border-bottom-color: rgba($offcanvas-menu-active-color, .2);
    }

    .inner {
      display: flex;
      align-items: center;

      .prepend {
        flex-grow: 1;
      }

      .append .v-btn {
        color: $offcanvas-menu-color;
      }
    }

    a, span {
      text-decoration: none;
      color: $offcanvas-menu-color;
      display: block;
      padding: .3rem 0;
    }

    .list {
      display: flex;

      flex-direction: column;

      padding: .75rem 0;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 101;

      background-color: $offcanvas-menu-background;
    }

    .header {
      display: flex;
      align-items: center;

      margin-bottom: .5rem;
      padding: 0 .75rem;

      .prepend {
        flex: 0 0 $btn-size;
        max-width: $btn-size;

        .v-btn {
          color: $offcanvas-menu-color;
        }
      }

      .heading {
        $width: calc(100% - #{$btn-size * 2});

        flex: 0 0 $width;
        max-width: $width;

        text-align: center;

        padding: 0 .5rem;

        span {
          display: block;

          font-size: 1.25rem;

          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
        }
      }
    }

    .overflow {
      flex-grow: 1;

      max-height: 100%;

      overflow-x: hidden;
      overflow-y: auto;
    }
  }
</style>
