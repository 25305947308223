<template>
  <section v-editable="blok" :class="{columns: true, 'colored-background': blok.coloredBackground}">
    <v-container fluid class="py-0">
      <v-row>
        <v-col :class="columnClassLeft">
          <columns-item :blok="blok" :side="'left'" :style="{minHeight: minHeightColumnLeft}"/>
        </v-col>
        <v-col :class="columnClassRight">
          <columns-item :blok="blok" :side="'right'" :style="{minHeight: minHeightColumnRight}"/>
        </v-col>
      </v-row>
    </v-container>
    </section>
</template>

<script>
  import ColumnsItem from '~/components/ColumnsItem'

  export default {
  	name: 'Columns',
    props: [
      'blok'
    ],
    components: {
      ColumnsItem: ColumnsItem
    },
    computed: {
      columnClassLeft() {
        const size = Number(this.getLayoutSize(0));

        if (this.blok.imageLeft && this.blok.imageStyleLeft === 'fullwidth') {
          return `col-12 col-sm-${size}`;
        } else {
          return `col-12 col-sm-${size - 1} offset-sm-1`;
        }
      },
      columnClassRight() {
        const size = Number(this.getLayoutSize(1));

        if (this.blok.imageRight && this.blok.imageStyleRight === 'fullwidth') {
          return `col-12 col-sm-${size}`;
        } else {
          return `col-12 col-sm-${size - 1}`;
        }
      },
      minHeightColumnLeft() {
        const ratio = this.getImageRatio(this.blok.imageLeft);
        if (!ratio) {
          return false;
        }

        if (this.blok.imageStyleLeft === 'inline') {
          return false;
        }

        const size = 12 / Number(this.getLayoutSize(0));

        return `calc(((100vw - 100%) / ${size}) * ${ratio})`;
      },
      minHeightColumnRight() {
        const ratio = this.getImageRatio(this.blok.imageRight);
        if (!ratio) {
          return false;
        }

        if (this.blok.imageStyleRight === 'inline') {
          return false;
        }

        const size = 12 / Number(this.getLayoutSize(1));

        return `calc(((100vw - 100%) / ${size}) * ${ratio})`;
      }
    },
    methods: {
      getLayoutSize(item) {
        return this.blok.layout.split(';')[item] || '6';
      },
      getImageRatio(src) {
        const values = !src ? [] : src.split('/');

        if (values.length)
        {
          const size = values[5].split('x');
          return size[1] / size[0];
        }

        return false;
      }
    }
  }
</script>

<style lang="scss">
  @import '~assets/scss/variables';

  .columns
  {
    height: 100%;
    width: 100%;

    &.colored-background
    {
      > .container
      {
        background: $colored-background;
      }
    }

    .column
    {
      height: 100%;

      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: flex-end;

      .image
      {
        height: 100%;
        position: relative;

        > .inner
        {
          > .v-image
          {
            height: 100%;
            position: absolute;
            top: 0;
          }
        }

        &.inline {
          margin: 0;
          height: auto;

          @media (min-width: map-get($grid-breakpoints, 'md')) {
            max-width: 400px;
          }

          > .inner {
            position: relative;
          }
        }
      }

      .content
      {
        margin: 0;
        padding: 24px;
      }

      &.column-left
      {
        .image
        {
          &.inline {
            @media (min-width: map-get($grid-breakpoints, 'md')) {
              position: absolute;
              top: 0;
              right: 0;
              width: 100%;
            }
          }

          > .inner > .v-image
          {
            right: 0;
          }
        }
      }

      &.column-right
      {
        .image> .inner > .v-image
        {
          left: 0;
        }
      }
    }
  }
</style>
