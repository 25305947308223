import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _379d9f79 = () => interopDefault(import('../pages/zoeken.vue' /* webpackChunkName: "pages/zoeken" */))
const _b997b446 = () => interopDefault(import('../pages/product-datasheet/_id.vue' /* webpackChunkName: "pages/product-datasheet/_id" */))
const _505dcafa = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/zoeken",
    component: _379d9f79,
    name: "zoeken"
  }, {
    path: "/product-datasheet/:id?",
    component: _b997b446,
    name: "product-datasheet-id"
  }, {
    path: "/*",
    component: _505dcafa,
    name: "all"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
