<template>
	<section
		class="text text-center"
		v-editable="blok"
	>
		<v-container>
			<v-row justify="center">
				<v-col cols="12" md="10" lg="8" xl="6" class="body">
					<h1
						v-if="blok.title"
						v-html="formatTitle(blok.title)"
						:class="blok.underlineTitle === 'none' ? false : `underline-${blok.underlineTitle}`"
					></h1>
					<div v-if="blok.text" v-html="$md.render(blok.text)"></div>
				</v-col>
			</v-row>
			<div
				:class="{highlights: true, 'small-grid': blok.items.length <= 4, 'large-grid': blok.items.length > 4}"
			>
				<div v-for="(item, i) in blok.items" :key="i">
					<Highlight :item="item"/>
				</div>
			</div>
		</v-container>
	</section>
</template>

<script>
	import Highlight from '~/components/Highlights/Highlight'

	export default {
		name: 'Highlights',
		props: ['blok'],
		components: {Highlight},
		methods: {
			formatTitle(string) {
				const pieces = string.split('\n')
				return pieces.length === 1 ? string : `<span>${pieces.join('</span><br><span>')}</span>`
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/_variables.scss";

	section {
		max-width: $max-site-width;
		margin: 0 auto;

		.body
		{
			> :not(h1) {
				margin-top: $gutter-width;
			}

			h1 {
				display: inline-block;
				color: $text-color;

				&[class*="underline"] {
					&:after {
						content: '';

						display: block;
						max-width: 110px;
						margin: 0 auto;

						padding-top: .5rem;
						border-bottom: 3px solid $text-color;
					}
				}

				&.underline-grey {
					&:after {
						border-color: map-get($grey, 'lighten-2');
					}
				}

				&.underline-blue {
					&:after {
						border-color: map-get($brand-colors, 'boring-blue');
					}
				}

				&.underline-green {
					&:after {
						border-color: map-get($brand-colors, 'green');
					}
				}

				&.underline-orange {
					&:after {
						border-color: map-get($brand-colors, 'orange');
					}
				}

				::v-deep > span {
					color: $heading-text-color;

					&:first-child {
						color: $heading-text-color-light;
					}
				}
			}
		}

		.highlights {
			$gutter: 1px;
			display: flex;
			flex-wrap: wrap;
			margin: -$gutter;
			position: relative;

			> div {
				padding: $gutter;

				> .highlight-item {
					display: flex;
					position: relative;
				}
			}

			&.small-grid {
				> div {
					flex: 1 0 50%;
					max-width: 50%;

					&:nth-child(even) {
						align-self: flex-end;
					}

					&:nth-child(odd) {
						> .highlight-item {
							margin-left: auto;
						}
					}

					&:not(:nth-child(3n+1)) {
						> .highlight-item {
							width: 90%;
						}
					}

					&:nth-child(3n) {
						::v-deep .v-image {
							padding-bottom: 65%;
						}
					}

					&:nth-child(3n+1) {
						align-self: flex-start;
					}

					::v-deep .v-image {
						height: 0;
						padding-bottom: 50%;
					}
				}
			}

			&.large-grid {
				$small-size: 34.5%;

				> div {
					flex: 1 0 50%;
					max-width: 50%;

					&:first-child {
						flex: 1 0 100 - $small-size;
						max-width: 100 - $small-size;
						padding-left: 5%;

						::v-deep .v-image {
							height: 0;
							padding-bottom: 60%;
						}
					}

					&:nth-child(2), &:nth-child(3) {
						flex: 1 0 $small-size;
						max-width: $small-size;
					}

					&:nth-child(2) {
						> .highlight-item {
							padding-top: 10%;
							width: 90%;

              &:before {
                margin-top: 10%;
                top: 1.8%;
              }
						}
					}

					&:nth-child(3) {
						position: absolute;
						right: 0;
						top: 0;
            width: 34.5%;
						transform: translateY(110%);

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
              top: $small-size;
              margin-top: -5px; // No idea why
              width: 100%;
              transform: none;
            }

            @supports (-ms-hyphens: auto) {
              top: $small-size;
              margin-top: -5px; // No idea why
              width: 100%;
              transform: none;
            }
					}

					&:nth-child(4) {
						flex: 1 0 $small-size;
						max-width: $small-size;
					}

					&:nth-child(5) {
						flex: 1 0 100 - ($small-size * 1.25);
						max-width: 100 - ($small-size * 1.25);

						::v-deep .v-image {
							height: 0;
							padding-bottom: 35%;
						}
					}

					::v-deep .v-image {
						height: 0;
						padding-bottom: 50%;
					}
				}
			}
		}
	}
</style>
