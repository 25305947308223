<template>
	<v-container class="body">
		<v-row align="center">
			<v-col>
        <h3 v-if="blok.subTitle">{{ blok.subTitle }}</h3>
				<h1 v-if="blok.title">{{ blok.title }}</h1>
			</v-col>
			<v-col>
				<ButtonLink
					v-if="is_link(blok.link)"
					:link="blok.link"
					:css-class="blok.image ? false : 'over-primary'"
				><slot v-if="blok.linkText">{{blok.linkText}}</slot></ButtonLink>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import ButtonLink from '~/components/util/ButtonLink';

	export default {
		name: 'BannerBody',
		props: ['blok'],
		components: {ButtonLink},
	}
</script>
