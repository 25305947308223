<template>
  <section v-editable="blok" itemscope itemtype="http://schema.org/Product">
    <meta itemprop="image" :content="mainImage">
    <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
      <meta itemprop="category" content="Speelprojecten">
      <meta itemprop="availability" content="OnlineOnly">
      <meta itemprop="price" content="0">
      <meta itemprop="priceCurrency" content="eur">
      <meta itemprop="priceValidUntil" content="12-12-2019">
      <meta itemprop="url" :content="pageUrl">
    </div>
    <v-container>
      <v-row align="center" class="breadcrumbs">
        <v-col class="shrink">
          <nuxt-link :to="overviewUrl" class="back">
            <v-icon>mdi-chevron-left</v-icon>
            <span>terug</span>
          </nuxt-link>
        </v-col>
        <v-col>
          <v-breadcrumbs :items="breadcrumbs"/>
        </v-col>
      </v-row>
      <v-divider class="mb-3"></v-divider>
      <v-row justify="center">
        <v-col cols="12" md="6" order="2" order-sm="1">
          <Gallery v-if="galleryImages.length" :items="galleryImages"/>
          <div class="specifications">
            <h3>Specificaties</h3>
            <ul>
              <li>
                <span>Artikelnummer:</span>
                <span itemprop="productID">{{ blok.articleNumber || '-' }}</span>
              </li>
              <li>
                <span>Productlijn:</span>
                <a
                  v-if="productLineUrl"
                  :href="productLineUrl"
                  itemprop="brand"
                >{{ blok.productLine || '-' }}</a>
                <span v-else itemprop="brand">{{ blok.productLine || '-' }}</span>
              </li>
              <li v-if="material">
                <span>Materiaal:</span>
                <span itemprop="material">{{ material }}</span>
              </li>
              <li v-if="blok.fallingHeight">
                <span>Vrije valhoogte:</span>
                <span>{{ blok.fallingHeight }}</span>
              </li>
              <li v-if="blok.ageGroupDatasheet">
                <span>Indicatie leeftijd:</span>
                <span>{{ blok.ageGroupDatasheet }}</span>
              </li>
              <li v-if="blok.measurements">
                <span>Toestelafmetingen:</span>
                <span>{{ blok.measurements }}</span>
              </li>
              <li v-if="blok.assemblyTime">
                <span>Montage excl. fundering:</span>
                <span>{{ blok.assemblyTime }}</span>
              </li>
              <li v-if="blok.requiredConcrete">
                <span>Benodigd beton:</span>
                <span>{{ blok.requiredConcrete }}</span>
              </li>
              <li v-if="blok.parts">
                <span>Samenstellende delen:</span>
                <span class="nl2br">{{ blok.parts }}</span>
              </li>
              <li v-if="blok.functionalities">
                <span>Speelfuncties:</span>
                <span>
                  <span
                    v-for="(functionality, index) in blok.functionalities"
                    :key="index"
                  >
                    {{ functionality }}<span v-if="index < Object.keys(blok.functionalities).length - 1">, </span>
                  </span>
                </span>
              </li>
            </ul>
          </div>
          <div class="related-products" v-if="Object.keys(relatedItems).length">
            <h3>Gerelateerde producten</h3>
            <v-row>
              <v-col
                v-for="(item, uid) in relatedItems"
                :key="uid"
                cols="12" sm="6" lg="4"
              >
                <product-item :item="item" :uid="uid" itemprop="isSimilarTo"/>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" md="6" order="1" order-sm="2">
          <h1 itemprop="name">{{ blok.name }}</h1>
          <p itemprop="description">{{ blok.descriptionShort }}</p>
          <div class="downloads mt-4">
            <h3>Downloads</h3>
            <v-btn
              @click="downloadDatasheet"
              text
              :loading="creatingDatasheet"
            >
              <img src="/icons/pdf.png" alt="PDF" width="23" height="29">
              <span>Product datasheet</span>
            </v-btn>
            <br>
            <v-btn
              v-if="blok.autocadDrawing"
              @click="dwgDialog = true"
              text
            >
              <img src="/icons/dwg.png" alt="DWG" width="23" height="32">
              <span>DWG tekening</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dwgDialog"
      width="500"
    >
      <v-card tile>
        <v-card-title class="grey lighten-2">
          Download
        </v-card-title>

        <v-card-text class="pb-0">
          <br>
          <p class="mb-6">Wij waarderen uw belangstelling in ons productaanbod en verzoeken u vriendelijk, eenmalig te willen registreren met uw e-mail adres.</p>

          <v-form
            v-model="dwgValid"
            ref="dwg-form"
            lazy-validation
            @submit.prevent="downloadDrawing"
          >
            <v-text-field
              outlined
              flat
              v-model="dwgEmail"
              label="Uw e-mailadres"
              :rules="emailRules"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="#005d77"
            outlined
            type="submit"
            @click.prevent="downloadDrawing"
            :disabled="!dwgValid"
          >
            Verstuur
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="dwgSnackbar"
    >
      Bedankt, u ontvangt z.s.m. een mail met daaraan toegevoegd de DWG tekening.
      <v-btn
        color="#f19727"
        text
        @click="dwgSnackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </section>
</template>

<script>
  import Gallery from '@/components/pages/product/Gallery'
  import ProductItem from '@/components/pages/ProductOverview/Product'
  const productlinePages = require('@/assets/js/productlines-pages')

	export default {
		name: 'ProductPage',
		props: ['blok'],
    components: {Gallery, ProductItem},
		data() {
			return {
				breadcrumbs: this.$store.state.breadcrumbs ? this.$store.state.breadcrumbs : [],
        relatedItems: [],
        creatingDatasheet: false,
        dwgDialog: false,
        dwgValid: false,
        dwgEmail: '',
				emailRules: [
					v => !!v || 'Het e-mailadres is verplicht',
					v => /@/.test(v) || 'E-mailadres is niet geldig'
				],
        dwgSnackbar: false
			}
		},
    computed: {
			productLineUrl() {
				return (this.blok.productLine) in productlinePages ? productlinePages[this.blok.productLine] : false
      },
			pageUrl() {
				return typeof window !== 'undefined' ? window.location.href : `${process.env.BASE_URL}${this.$nuxt.$route.fullPath}`
      },
			favorites() {
				return this.$store.state.favorites
      },
	    overviewUrl() {
	    	return `/${(this.$attrs.full_slug).substring(0, ((this.$attrs.full_slug).lastIndexOf('/') === -1 ?
			    (this.$attrs.full_slug).length :
			    (this.$attrs.full_slug).lastIndexOf('/')))}`
      },
      isFavorite() {
		    return (this.favorites).includes(this.$attrs.page_uid)
      },
      galleryImages() {
				return [...this.blok.images, ...this.blok.renders, ...this.blok.blueprints].filter(Boolean)
      },
      mainImage() {
	      let image = null
	      if ((this.blok.images).length) {
		      image = this.blok.images[0].image
	      }
	      else if ((this.blok.renders).length) {
		      image = this.blok.renders[0].image
	      }
	      else if ((this.blok.blueprints).length) {
		      image = this.blok.blueprints[0].image
	      }

	      return image;
      },
      material() {
        if (this.blok.materialDescription.length === 0) {
          switch (this.blok.productLine)
          {
            case 'robuust robinia':
              return 'spintvrij & kernrotvrij robinia in natuurlijke groeivorm, standaard voorzien van een transparante lazuurlaag; kopse einden van het rondhout voorzien van houtdraadbouten tegen inscheuren, boorgaten opgevuld. Platformen, daken en wanden: 38 mm dik robinia. Touwen: polyamide herculestouw Ø 25 mm.'
            case 'robuust staal':
              return 'staanders: standaard thermisch verzinkt staal (poedercoating tegen meerprijs en alternatief verkrijgbaar in roestvast staal). Platformen, daken en wanden 38 mm dik robinia. Daken en wanden indien kunststof: 19 mm HDPE. Touwen: polyamide herculestouw Ø 25 mm.'
            case 'speelkunst':
              return 'speels robiniahout in de oorspronkelijke groeivorm, geschild, spintvrij geslepen en geschuurd, standaard ongelazuurd (blank), kopse kanten voorzien van kruisverbindingen tegen doorscheuren, metalen delen RVS. Eventuele kleuraccenten als afgebeeld of naar keuze uit kleurkaart.'
            default:
              return this.blok.material
          }
        }

        return this.blok.materialDescription;
      }
    },
    methods: {
	    async getRelatedItem() {
        if (!this.blok.articleNumber) {return;}

        let stories = {};
        const regex = new RegExp(/^.*\d+(?=\D*$)/g);
        const toCompare = regex.exec(this.blok.articleNumber);

        if (toCompare && toCompare[0]) {
          const storiesResult = await this.$storyapi.get('cdn/stories', {
            'filter_query[component][in]': 'page_product',
	          'search_term': toCompare[0],
            'excluding_ids': this.$attrs.page_id,
            'story_only': true,
            'is_published': true,
	          'per_page': 100,
            'sort_by': 'position:DESC',
          });

          if (!storiesResult.data.stories) {return;}

          storiesResult.data.stories.forEach((story) => {
	          let image = null
	          if ((story.content.images).length) {
		          image = story.content.images[0].image
	          }
	          else if ((story.content.renders).length) {
		          image = story.content.renders[0].image
	          }
	          else if ((story.content.blueprints).length) {
		          image = story.content.blueprints[0].image
	          }

            stories[story.uuid] = {
              slug: story.full_slug,
              title: story.content.name,
              productLine: story.content.productLine,
              articleNumber: story.content.articleNumber,
	            favorite: this.favorites.includes(story.uuid),
              image
            };
          });
        }

		    this.relatedItems = stories;
	    },
	    toggleFavorite() {
		    let favorites = JSON.parse(JSON.stringify(this.favorites));

		    if (favorites.includes(this.$attrs.page_uid)) {
			    favorites = favorites.filter(e => e !== this.$attrs.page_uid)
		    } else {
			    favorites.push(this.$attrs.page_uid)
		    }

		    this.$store.commit('setFavorites', favorites);
	    },
      goToInvoice() {
        if (!this.isFavorite) {
        	this.toggleFavorite()
        }

	      if (this.$options.filters.is_link(this.$store.state.settings.favoritesPage)) {
		      this.$router.push(this.$options.filters.url(this.$store.state.settings.favoritesPage))
	      }
      },
      async downloadDatasheet () {
	    	this.creatingDatasheet = true;
	      const pdf = await this.$axios.$get(`/api/puppeteer/product-datasheet/${this.$attrs.page_id}`, {
		      responseType: 'arraybuffer',
		      headers: {
			      'Accept': 'application/pdf'
		      }
        })

	      const blob = new Blob([pdf], {type: 'application/pdf'})
	      const link = document.createElement('a')
	      link.href = window.URL.createObjectURL(blob)
	      link.download = `${this.blok.articleNumber} - ${this.blok.name} - Product datasheet.pdf`
	      link.click()

	      this.creatingDatasheet = false;
      },
      async downloadDrawing() {
	      this.dwgValid = this.$refs['dwg-form'].validate();

	      if (!this.dwgValid) return;

        const attachments = [{
	    		filename: (this.blok.autocadDrawing).substring((this.blok.autocadDrawing).lastIndexOf('/') + 1),
          path: 'https:' + this.blok.autocadDrawing
        }]

	      await this.$axios.$post('/api/dwg', {email: this.dwgEmail, attachments});

        this.dwgDialog = false;
        this.dwgSnackbar = true;
      }
    },
    mounted() {
			this.getRelatedItem()
    }
	}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  section {
    max-width: $max-site-width;
    margin: 0 auto;
  }

  h3 {
    font-weight: $font-weight-bold;
  }

  .v-breadcrumbs {
    padding: 0;
  }

  .product-gallery {
    margin-bottom: 1rem;
  }

  .specifications, .related-products {
    margin-top: $gutter-width * 2;
  }

  .specifications {
    ul {
      padding: 0;
      list-style: none;

      > li {
        > span {
          &:first-child {
            font-weight: bold;
          }

          &.nl2br {
            white-space: pre-line;
          }
        }

        > div {
          display: inline-block;
        }
      }
    }
  }

  .actions {
    button {
      -webkit-appearance: none;
      outline: none;
      padding: .5rem 1.5rem;
      width: 100%;
      border: {
        width: 2px;
        style: solid;
        radius: 3rem;
      }

      transition: background .2s ease, color .2s linear;

      > * {
        vertical-align: middle;
      }

      > .v-icon {
        margin-left: .5rem;
      }

      &.invoice {
        border-color: map-get($brand-colors, 'blue-grey');
        background: map-get($brand-colors, 'blue-grey');

        &, > .v-icon {
          color: #fff;
        }

        &:hover {
          background: map-get($brand-colors, 'blue');
        }
      }

      &.favorite {
        border-color: map-get($brand-colors, 'orange');

        &, > .v-icon {
          color: map-get($brand-colors, 'orange');
        }

        &:hover {
          background: map-get($brand-colors, 'orange');

          &, > .v-icon {
            color: #fff;
          }
        }
      }
    }
  }

  .downloads {
    h3 {
      margin-bottom: .5rem;
    }

    .v-btn {
      color: $link-color;
      letter-spacing: normal;
      text-transform: none;
      font-size: 1rem;
      margin-left: -$gutter-width;

      img {
        margin-right: .5rem;
      }

      &:not(:last-child) {
        margin-bottom: .5rem;
      }
    }
  }

  .related-products {

    > h3 {
      margin-bottom: $gutter-width
    }

    ::v-deep .product {
      h3 {
        font-size: 1.25rem;
      }
    }
  }
</style>
