export const state = () => ({
	cacheVersion: '',
	draftMode: false,
  favorites: []
});

export const mutations = {
	setCacheVersion (state, version) {
		state.cacheVersion = version
	},
	setDraftMode(state, draftMode) {
		state.draftMode = draftMode;
	},
	setBreadcrumbs(state, breadcrumbs) {
		state.breadcrumbs = breadcrumbs;
	},
	setLinks(state, links) {
		state.links = links;
	},
	setSettings(state, settings) {
		state.settings = settings;
	},
  setFavorites(state, favorites) {
	  state.favorites = favorites;
  },
  setProductCategoryLinks(state, links) {
	  state.productCategoryLinks = links;
  }
};

export const actions = {
	loadCacheVersion ({ commit }) {
		return this.$storyapi.get(`cdn/spaces/me`).then((res) => {
			commit('setCacheVersion', res.data.space.version)
		})
	},
	loadLinks({commit}) {
		return this.$storyapi.get(`cdn/links`).then((res) => {
			commit('setLinks', res.data.links)
		});
	},
	loadSettings({commit}, context) {
		return this.$storyapi.get(`cdn/stories/settings`, {
			version: context.version
		}).then((res) => {
			commit('setSettings', res.data.story.content)
		})
	},
};

export const getters = {
  favorites(state) {
    return state.favorites
  },
  favoriteCount(state) {
    return (state.favorites).length
  },
}
