<template>
	<section
      v-editable="blok"
			:class="{text: true, 'text-center': blok.textCenter, 'colored-background': blok.coloredBackground}"
	>
		<v-container>
			<v-row justify="center">
				<v-col cols="12" md="10" lg="8" xl="6" class="body">
					<h1
						v-if="blok.title"
						v-html="formatTitle(blok.title)"
						:class="blok.underlineTitle === 'none' ? false : `underline-${blok.underlineTitle}`"
					></h1>
          <div v-if="blok.text" v-html="$md.render(blok.text)"></div>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
	export default {
		name: 'TextBlock',
		props: ['blok'],
    methods: {
      formatTitle(string) {
      	const pieces = string.split('\n')
        return pieces.length === 1 ? string : `<span>${pieces.join('</span><br><span>')}</span>`
      }
    }
	}
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/_variables.scss";

	section
	{
		&.colored-background {
			background: $colored-background;
		}

    &.text-center {
      h1[class*="underline"]:after {
        margin: 0 auto;
      }
    }

		.body
    {
      > :not(h1) {
        margin-top: $gutter-width;
      }

      h1 {
	      display: inline-block;
	      color: $text-color;

				&[class*="underline"] {
					&:after {
						content: '';

						display: block;
						max-width: 110px;

						padding-top: .5rem;
						border-bottom: 3px solid $text-color;
					}
				}

	      &.underline-grey {
		      &:after {
		        border-color: map-get($grey, 'lighten-2');
		      }
	      }

	      &.underline-blue {
		      &:after {
		        border-color: map-get($brand-colors, 'boring-blue');
		      }
	      }

	      &.underline-green {
		      &:after {
		        border-color: map-get($brand-colors, 'green');
		      }
	      }

	      &.underline-orange {
		      &:after {
		        border-color: map-get($brand-colors, 'orange');
		      }
	      }

        ::v-deep > span {
	        color: $heading-text-color;

          &:first-child {
            color: $heading-text-color-light;
          }
        }
      }

      > div {
        ::v-deep > p:last-child {
          margin-bottom: 0;
        }
      }
    }
	}
</style>
