<template>
  <div v-editable="blok">
    <component :key="blok._uid" v-for="blok in blok.body" :blok="blok" :is="blok.component | dashify"></component>

    <section id="product-category-overview">
      <v-container>
        <v-row>
          <v-col
            cols="12" sm="5" md="4" lg="3"
          >
            <Menu/>
          </v-col>
          <v-col
            cols="12" sm="7" md="8" lg="9"
          >
            <v-row dense>
              <v-col cols="12" v-if="blok.title || blok.text" class="mb-12">
                <h1 v-if="blok.title">{{ blok.title }}</h1>
                <div
                  v-if="blok.text"
                  v-html="$md.render(blok.text)"
                  :class="{'mt-4': blok.title}"
                ></div>
              </v-col>
              <v-col
                v-for="(item, i) in items" :key="i"
                cols="12" sm="6" md="4"
              >
                <v-card
                  class="mx-auto product-category-item"
                  nuxt
                  hover
                  tile
                  :flat="!!item.image"
                  :to="`/${item.slug}`"
                >
                  <v-img
                    v-if="item.image"
                    itemprop="image"
                    aspect-ratio="1.5"
                    :src="item.image | resize('400x0')"
                    :lazy-src="item.image | resize('100x0')"
                    :srcset="srcset(item.image)"
                    sizes="auto"
                    :alt="item.title"
                    gradient="rgba(0,93,119,0) 60%, rgba(0,93,119,.5)"
                    class="align-end"
                  >
                    <template slot="placeholder">
                      <v-row class="fill-height ma-0"
                           align="center"
                           justify="center"
                      >
                        <v-progress-circular indeterminate color="#00A8E7"></v-progress-circular>
                      </v-row>
                    </template>
                    <v-card-title>
                      <span>{{ item.title }}</span>
                    </v-card-title>
                  </v-img>
                  <v-card-title
                    v-else
                    class="fill-height"
                  >
                    <span>{{ item.title }}</span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <invoice/>
  </div>
</template>

<script>
  import Menu from '@/components/pages/ProductCategoryOverview/Menu'
  import Invoice from '~/components/Invoice'
  import _ from 'lodash'

	export default {
		name: 'ProductCategoryOverviewPage',
		props: ['blok'],
    components: {Menu, Invoice},
		data() {
			return {
				items: []
			}
		},
		methods: {
			async loadItems() {
				const storiesResult = await this.$storyapi.get('cdn/stories', {
					'filter_query[component][in]': 'page_product_overview,page_product_category_overview',
					'per_page': 100,
          'story_only': true,
          'is_published': true,
					'starts_with': this.$attrs.full_slug,
					'sort_by': 'position:DESC'
				});

				if (!storiesResult.data.stories) {
					return;
				}

				const productCategoryLinks = this.$store.state.productCategoryLinks;
				const depth = ((this.$attrs.full_slug).split('/')).length + 1;
        const productIndex = _.findIndex(productCategoryLinks, {'slug': this.$attrs.full_slug});

				let stories = {};
				storiesResult.data.stories.forEach((story) => {
          if (((story.full_slug).split('/')).length === depth) {
          	const index = productIndex >= 0 ?
              _.findIndex(productCategoryLinks[productIndex].children, {'uuid': story.uuid}) :
              _.findIndex(productCategoryLinks, {'uuid': story.uuid})
            stories[story.uuid] = {
              slug: story.full_slug,
              title: story.name,
              image: story.content.overviewImage || null,
              position: index >= 0 ? index : 999999
            };
          }
				});

				stories = _.orderBy(stories, ['position'], ['asc'])

				this.items = stories;
			},
			srcset(image, ratio) {
				const that = this;
				let src = '';

				[400, 600, 900, 1600].forEach((width) => {
					src += `${that.$options.filters.resize(image, `${width}x${ratio ? Math.round(width / ratio) : 0}`)} ${width}w, `;
				});

				src = src.slice(0, -2);

				return src;
			}
		},
		mounted() {
			this.loadItems();
		},
	}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  #product-category-overview {
    > .container {
      max-width: $max-site-width;
    }
  }

  .product-category-item {
    .v-card__title {
      font: {
        weight: $font-weight-bold;
        size: 1.25rem;
      }
    }

    .v-image {
      position: relative;
      .v-card__title {
        color: #fff;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
      }
    }
  }
</style>
