<template>
  <v-app>
    <nuxt/>
  </v-app>
</template>

<script>
	export default {
		name: 'datasheet',
	}
</script>

<style lang="scss" scoped>
  #app {
    background: #fff;
    padding: 6mm 11mm 3mm 11mm;

    overflow: hidden;
    width: 210mm; // content --> 188mm
    height: 297mm;

    ::v-deep .v-application--wrap {
      min-height: 0;
    }
  }

  #header, #footer {
    padding: 0;
    margin: 0;
  }
</style>
