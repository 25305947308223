<template>
  <div v-editable="blok">
    <component :key="blok._uid" v-for="blok in blok.body" :blok="blok" :is="blok.component | dashify"></component>
    <section id="favorites">
      <v-container>
        <v-row align="center" justify="space-between">
          <v-col class="auto">
            <h1>Favorieten</h1>
          </v-col>
          <v-col class="shrink">
            <share-favorites/>
          </v-col>
        </v-row>
        <v-divider/>

        <v-progress-linear
          indeterminate
          height="10"
          color="#60af40"
          v-if="loading"
          class="mt-8"
        ></v-progress-linear>
        <div
          v-else-if="!loading && Object.keys(favoriteItems).length"
        >
          <v-row>
            <v-col
              v-for="(item, uid) in favoriteItems"
              :key="uid"
              cols="12" sm="6" md="4" lg="3"
            >
              <product-item :item="item" :uid="uid" @unfavorite="remove(uid)"/>
            </v-col>
          </v-row>
        </div>
        <p v-else>Welke producten wenst u aan te vragen? Maak een selectie uit ons productassortiment.</p>
      </v-container>
    </section>
    <section id="invoice-form" v-if="Object.keys(favoriteItems).length">
      <v-container>
        <h1 v-if="blok.formTitle">{{ blok.formTitle }}</h1>
        <div
          v-if="blok.formText"
          v-html="$md.render(blok.formText)"
        ></div>
        <v-row justify="center">
          <v-col cols="12" sm="10" md="8" lg="6" xl="4">
            <invoice-form :thanks="blok.formThanks"/>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
	import ProductItem from '@/components/pages/ProductOverview/Product'
  import ShareFavorites from '@/components/util/ShareFavorites'
  import InvoiceForm from '@/components/InvoiceForm'

	export default {
		name: 'FavoritesPage',
		props: ['blok'],
    components: {ProductItem, ShareFavorites, InvoiceForm},
		data() {
			return {
				favoriteItems: [],
        loading: true,
        mounted: false
			}
		},
		computed: {
			initialFavorites() {
				return this.$store.state.favorites
			},
		},
    watch: {
	    initialFavorites(favorites) {
				if (this.loading || (this.mounted && !this.favoriteItems.length)) {
          this.loadFavorites(favorites)
        }
      }
    },
    mounted() {
			this.mounted = true;

	    if (this.loading) {
		    this.loadFavorites(this.$store.state.favorites)
	    }
    },
		methods: {
			async loadFavorites(favs) {
				const favorites = JSON.parse(JSON.stringify(favs));
				if (!favorites.length) {this.loading = false; return;}

				let stories = {};

        const storiesResult = await this.$storyapi.get('cdn/stories', {
          'by_uuids_ordered': favorites.join(','),
          'story_only': true,
          'is_published': true,
	        'per_page': 100,
          'sort_by': 'position:DESC',
        });

        if (!storiesResult.data.stories) {return;}

        storiesResult.data.stories.forEach((story) => {
          let image = null;
          if ((story.content.images).length) {
            image = story.content.images[0].image
          }
          else if ((story.content.renders).length) {
            image = story.content.renders[0].image
          }
          else if ((story.content.blueprints).length) {
            image = story.content.blueprints[0].image
          }

          stories[story.uuid] = {
            slug: story.full_slug,
            title: story.content.name,
            productLine: story.content.productLine,
            articleNumber: story.content.articleNumber,
            image,
            favorite: true
          };
        });

        this.loading = false;
				this.favoriteItems = stories;
			},
      remove(uid) {
	      this.$delete(this.favoriteItems, uid)
      }
		},
	}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  #favorites, #invoice-form > .v-container {
    max-width: $max-site-width;
    margin: 0 auto;
  }

  #favorites {
    h1 {
      color: map-get($brand-colors, 'orange');
    }
  }

  #invoice-form {
    text-align: center;
    background: $colored-background;

    h1 {
      color: $text-color;
      display: inline-block;
      position: relative;

      &:after {
        content: '';

        display: block;
        max-width: 110px;
        padding-top: .5rem;
        margin: 0 auto 1.5rem;
        border-bottom: 3px solid map-get($brand-colors, 'orange');
      }
    }
  }

  ::v-deep #share-favorites {
    z-index: 10;
  }
</style>
