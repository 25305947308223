<template>
  <nav role="navigation">
    <ul>
      <li
        v-for="item in items"
        :key="item._uid"
        @mouseover="mouseover(item._uid)"
        @mouseleave="mouseleave"
      >
        <app-link
          v-if="is_link(item.link)"
          :to="item.link | url"
          :class="item.borderColor ? `border-${item.borderColor}` : null"
        >
          <span>{{ item.name }}</span>
          <v-icon v-if="item.children.length">mdi-chevron-down</v-icon>
        </app-link>
        <sub-menu v-if="item.children.length" :items="item.children" :active="currentHover === item._uid"/>
      </li>
    </ul>
  </nav>
</template>

<script>
  import AppLink from '@/components/util/AppLink'
  import SubMenu from '@/components/Menu/OnCanvas/Sub'

	export default {
		name: 'OnCanvasMenu',
    components: {AppLink, SubMenu},
		data() {
			return {
				currentHover: false,
				mouseleaveTimer: false
			}
		},
		computed: {
			items() {
				return this.$store.state.settings.mainNav || [];
			}
		},
    methods: {
	    mouseover(index) {
		    this.currentHover = index
		    clearTimeout(this.mouseleaveTimer)
	    },
	    mouseleave() {
		    this.mouseleaveTimer = setTimeout(() => { this.currentHover = false }, 1000)
	    }
    }
	}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  nav {
    height: 100%;
    padding: 4px 0;
    text-align: center;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      > li {
        display: inline-block;
      }
    }

    > ul {
      height: 100%;

      > li {
        text-align: left;
        height: 100%;
        vertical-align: top;
        padding: 0 2px;

        > a {
          position: relative;
          height: 100%;
          display: flex;
          align-items: center;
          text-decoration: none;
          padding: 0 .5rem;
          font-weight: $font-weight-bold;

          color: map-get($brand-colors, 'blue-grey');

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            height: 6px;
            background: map-get($brand-colors, 'green');
            transition: height .15s;
          }

          > .v-icon {
            margin-left: .25rem;
            color: map-get($brand-colors, 'green');
            font-size: 1rem;

            @media (max-width: 1080px) {
              display: none;
            }
          }

          &.border-orange:after {
            background: map-get($brand-colors, 'orange')
          }

          &.border-yellow:after {
            background: map-get($brand-colors, 'yellow')
          }

          &.border-blue:after {
            background: map-get($brand-colors, 'boring-blue')
          }

          &.border-blue-grey:after {
            background: map-get($brand-colors, 'blue-grey')
          }

          &.border-brown:after {
            background: map-get($brand-colors, 'brown')
          }

          &.nuxt-link-exact-active,
          &:hover {
            &:after {
              height: 10px;
            }
          }
        }
      }
    }
  }
</style>
