<template>
  <section>
    <div class="container">
      <v-row justify="center">
        <v-col cols="12" md="10" lg="8" xl="6" class="body">
          <h1>{{ this.$store.state.settings.invoiceTitle || 'Offerte aanvragen'}}</h1>
          <div v-if="this.$store.state.settings.invoiceText" v-html="$md.render(this.$store.state.settings.invoiceText)"></div>
          <nuxt-link
            v-if="is_link(this.$store.state.settings.favoritesPage)"
            nuxt
            :to="(this.$store.state.settings.favoritesPage)|url"
          >
            Offerte aanvragen
          </nuxt-link>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
	export default {
		name: 'InvoiceBlock'
	}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  section {
    background: $colored-background;

    > .container {
      max-width: $max-site-width;
      text-align: center;
    }

    .body {
      h1 {
        display: inline-block;
        color: $text-color;
        position: relative;

        &:after {
          content: '';

          display: block;
          max-width: 110px;
          margin: 0 auto;

          padding-top: .5rem;
          border-bottom: 3px solid map-get($brand-colors, 'orange');
        }
      }

      > div {
        margin-top: $gutter-width;

        + a {
          margin-top: $gutter-width * 2;
        }

        ::v-deep > p:last-child {
          margin-bottom: 0;
        }
      }

      > a {
        display: inline-block;
        border: 1px solid map-get($brand-colors, 'orange');
        outline: none;
        border-radius: 2rem;
        background: #fff;
        padding: .75rem 2rem;

        text: {
          decoration: none;
          align: center;
        }
        color: $text-color;
        font-weight: $font-weight-bold;

        transition: box-shadow .1s, color .2s;

        &:active {
          box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
        }

        &:hover {
          color: map-get($brand-colors, 'blue');

          > .v-icon {
            color: map-get($brand-colors, 'blue');
          }
        }
      }
    }
  }
</style>
