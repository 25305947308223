<template>
	<footer itemscope itemtype="http://schema.org/Corporation" id="footer">
		<meta itemprop="url" :content="baseUrl">
		<meta itemprop="address" content="Kraaivenstraat 23-12, 5048 AB Tilburg">
		<meta itemprop="email" content="goede@speelprojecten.nl">
		<meta itemprop="logo" :content="`${baseUrl}/logo.svg`">
		<meta itemprop="telephone" content="+314551605">

    <v-container>
      <v-row>
        <v-col cols="4" md="3" lg="3" xl="3" offset="4" offset-md="0">
          <nuxt-link to="/" class="logo">
            <Logo/>
          </nuxt-link>
        </v-col>
        <v-col cols="6" md="3" lg="3" xl="3">
          <h3>{{ this.$store.state.settings.footerMenu1Title }}</h3>
	        <ul>
            <li
			        v-for="(item, i) in this.$store.state.settings.footerMenu1"
			        :key="i"
		        >
	            <AppLink
		            v-if="is_link(item.to)"
		            :to="item.to | url"
	            >{{ item.text }}</AppLink>
		        </li>
	        </ul>
        </v-col>
        <v-col cols="6" md="3" lg="3" xl="3">
          <h3>{{ this.$store.state.settings.footerMenu2Title }}</h3>
	        <ul>
		        <li
			        v-for="(item, i) in this.$store.state.settings.footerMenu2"
			        :key="i"
		        >
			        <AppLink
				        v-if="is_link(item.to)"
				        :to="item.to | url"
			        >{{ item.text }}</AppLink>
		        </li>
	        </ul>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3">
          <h3>Nederland</h3>
          <a href="tel:+31134551605">+31 13 455 1605</a>
          <a href="mailto:goede@speelprojecten.nl">goede@speelprojecten.nl</a>
          <h3 class="mt-6">België</h3>
          <a href="tel:+3234824067">+32 3 482 4067</a>
          <a href="mailto:goede@speelprojecten.be">goede@speelprojecten.be</a>
        </v-col>
      </v-row>
    </v-container>

        <AppLink to="/"/>

		<div>
			<v-container>
				<v-row>
					<v-col cols="auto" class="mr-auto">&copy; Goede Speelprojecten</v-col>
          <v-col cols="auto">
            <a href="https://softmedia.nl" target="_blank">Webdesign & development Softmedia</a>
          </v-col>
				</v-row>
			</v-container>
		</div>
	</footer>
</template>

<script>
	import Logo from '~/static/logo-white.svg';
	import AppLink from '~/components/AppLink';

	export default {
		name: 'Footer',
		components: { AppLink, Logo },
		computed: {
			baseUrl() {
				return process.env.BASE_URL
			},
		},
	}
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/_variables.scss";

	footer {
    background: #99c783;

		> div {
      &:first-of-type {
        max-width: $max-site-width;
        margin: 0 auto;
        padding: {
          top: 3rem;
          bottom: 3rem;
        }

	      .logo {
		      display: block;
		      max-width: 200px;

	        @media
	        (max-width: map-get($grid-breakpoints, 'lg') - 1) and
	        (min-width: map-get($grid-breakpoints, 'md')) {
		        padding-left: $gutter-width;
	        }
	      }

        h3 {
          color: #fff;
          font-weight: $font-weight-bold;
        }

        a {
	        display: inline-block;
	        position: relative;
          color: #fff;
          text-decoration: none;

	        @media (min-width: map-get($grid-breakpoints, 'lg')) {
		        font-size: 110%;
	        }

	        &:not(.logo) {
		        &:after {
			        content: '';
			        position: absolute;
			        bottom: -1px;
			        height: 1px;
			        background: #fff;

			        left: 50%;
			        width: 0;
			        opacity: 0;

			        transition: left .2s, width .2s, opacity .2s;
		        }

		        &:hover {
			        &:after {
				        width: 100%;
				        left: 0;
				        opacity: 1;
			        }
		        }
	        }
        }
      }

      &:last-child {
        background: map-get($brand-colors, 'green');

        &, a {
          color: #003a4a;
        }

        a {
          text-decoration: none;

          &:hover {
            color: #fff;
          }
        }
      }
    }

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
		}
	}
</style>
