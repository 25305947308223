import Vue from 'vue'

Vue.mixin({methods: {'is_link': (link) => {
	const links = Vue.prototype.$nuxt.$store.state.links;

	return links && typeof link !== 'undefined' && (
		(link.linktype === 'story' && link.id !== '' && links.hasOwnProperty(link.id)) ||
		(link.linktype === 'url' && link.url !== '')
	);
}}});
