module.exports = {
  productLine: {
    label: 'Productlijn',
    values: {
      'robuust robinia': 'Robuust robinia',
      'robuust staal': 'Robuust staal',
      'robuust design': 'Robuust design',
      'varioset mini': 'Varioset mini',
      'kidstramps': 'Kidstramps',
      'speelkunst': 'Speelkunst',
      'hightech adrenaline': 'Hightech adrenaline',
      'hightech amico': 'Hightech amico',
      'hightech fun4all': 'Hightech fun4all',
      'hightech klimtollen': 'Hightech klimtollen',
      'hightech ruimtenetten': 'Hightech ruimtenetten',
      'hightech silhouette': 'Hightech silhouette',
      'overig': 'Overig',
    }
  },
  fallingHeightGroup: {
    label: 'Valhoogte',
    values: {
      0: 'n.v.t.',
      60: 'tot 60 cm',
      100: 'tot 1.00 m',
      150: 'tot 1.50 m',
      '150+': 'boven 1.50 m'
    }
  },
  ageGroup: {
    label: 'Leeftijd',
    multiple: true,
    values: {
      '0-3': '0 - 3 jaar',
      '3-6': '3 - 6 jaar',
      '6-9': '6 - 9 jaar',
      '9-12': '9 - 12 jaar',
      '12-15': '12 - 15 jaar',
      '15+': '15 jaar en ouder'
    }
  },
  functionalities: {
    label: 'Speelfunctie',
    multiple: true,
    values: {
      'apekooien': 'Apenkooien',
      'balanceren': 'Balanceren',
      'constructiespel': 'Constructiespel',
      'draaien': 'Draaien',
      'duikelen': 'Duikelen',
      'enteren': 'Enteren',
      'glijden': 'Glijden',
      'in balans blijven': 'In balans blijven',
      'klimmen en klauteren': 'Klimmen en klauteren',
      'kruipen': 'Kruipen',
      'roetsjen': 'Roetsjen',
      'rollen- en fantasiespel': 'Rollen- en fantasiespel',
      'schommelen': 'Schommelen',
      'slingeren': 'Slingeren',
      'spelen met/zonder beperking': 'Spelen met/zonder beperking',
      'springen en huppelen': 'Springen en huppelen',
      'wiegen': 'Wiegen',
      'wippen': 'Wippen',
      'zintuigen en fijne motoriek': 'Zintuigen en fijne motoriek',
      'ontmoeten/zitten': 'Ontmoeten en zitten',
      'oversteken': 'Oversteken',
      'uitkijken': 'Uitkijken',
      'wiebelen': 'Wiebelen',
      'snelheid beleven': 'Snelheid beleven',
      'spelen met water': 'Spelen met water',
      'balspel': 'Balspel'
    }
  }
}
