<template>
  <div :class="{'sub-menu': true, active}">
    <div>
      <ul
        v-for="(listItems, i) in lists"
        :key="i"
      >
        <li
          v-for="(item, i) in listItems"
          :key="i"
        >
          <app-link
            v-if="is_link(item.link)"
            :to="item.link | url"
          >
            <span>{{item.name}}</span>
            <v-icon>mdi-chevron-right</v-icon>
          </app-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
	import AppLink from '@/components/util/AppLink'

	export default {
		name: 'OnCanvasSubMenu',
    components: {AppLink},
    props: {
			items: {
				type: [Object, Array, null],
        default: (() => {return []})
      },
      active: {
				type: Boolean,
        default: false
      }
    },
    computed: {
			lists() {
				if (this.items.length) {
					return _.chunk(this.items, Math.ceil(this.items.length / 4))
        } else {
					return []
        }
      }
    }
	}
</script>


<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  .sub-menu {
    position: fixed;
    pointer-events: none;
    z-index: 10;
    top: $header-height-small;
    left: 0;
    right: 0;
    margin-top: -100vh;
    min-height: 100px;
    padding-bottom: $gutter-width * 3;

    background: rgb(238, 246, 249);
    transition: top .2s, opacity .2s .1s, transform .2s, margin 0s .2s;

    opacity: 0;
    transform: translateY(-10%);

    &.active {
      pointer-events: auto;
      transition: top .2s, opacity .2s .1s, transform .2s, margin 0s 0s;
      margin-top: 0;
      opacity: 1;
      transform: translateY(0);
    }

    &:before {
      content: '';
      height: $gutter-width * 2;
      display: block;
      background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,8%) 100%);
    }

    > div {
      margin: 0 auto;
      padding-top: $gutter-width;
      max-width: $max-site-width;
      display: flex;

      > ul {
        flex: 1 0 25%;
        padding: 0 $gutter-width;

        @media (min-width: map-get($grid-breakpoints, 'xl')) {
          padding: 0 $gutter-width * 2;
        }
      }
    }

    ul {
      margin: 0;
      list-style: none;

      li {
        &:last-child {
          a {
            border-bottom: none;
          }
        }
      }
    }

    a {
      display: block;
      text-decoration: none;
      padding: .5rem 0;

      color: map-get($brand-colors, 'blue-grey');
      border-bottom: 1px solid map-get($brand-colors, 'blue');

      transition: color .2s;

      > .v-icon {
        color: map-get($brand-colors, 'blue');
        font-size: 1.25rem;
        opacity: 0;

        transition: all .2s;
      }

      &:hover {
        color: map-get($brand-colors, 'blue');

        > .v-icon {
          opacity: 1;
          padding-left: .5rem;
        }
      }

      &.nuxt-link-exact-active {
        color: map-get($brand-colors, 'green');

        > .v-icon {
          display: none;
        }
      }
    }
  }
</style>
