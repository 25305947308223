<template>
  <nav>
    <v-list>
      <template v-for="(item, i) in items">
        <v-divider
          :key="`divider-${i}`"
          v-if="i !== 0"
        ></v-divider>
        <v-list-group
          :key="`item-group-${i}`"
          v-if="item.children"
          eager
          :value="item.active"
          :class="{current: item.active}"
        >
          <template v-slot:activator>
            <v-list-item-content
              :key="`item-${i}`"
              nuxt
              :to="`/${item.slug}`"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:default>
            <template v-for="(child, childI) in item.children">
              <v-divider
                :key="`divider-${i}-${childI}`"
                v-if="i !== 0"
              ></v-divider>
              <v-list-item
                class="pl-11"
                :key="`item-${i}-${childI}`"
                nuxt
                :to="`/${child.slug}`"
              >
                {{ child.title }}
              </v-list-item>
            </template>
          </template>
        </v-list-group>
        <v-list-item
          :key="`item-${i}`"
          nuxt
          :to="`/${item.slug}`"
          v-else
          active-class="current"
        >
          {{ item.title }}
        </v-list-item>
      </template>
    </v-list>
  </nav>
</template>

<script>
	export default {
		name: 'ProductCategoryOverviewMenu',
		data() {
			return {
        items: this.$store.state.productCategoryLinks,
			}
		},
	}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  .v-list-group {
    &.current {
      color: map-get($brand-colors, 'blue');

      .v-list-item__title {
        text-decoration: underline;
      }
    }
  }

  .v-list-item--active {
    color: map-get($brand-colors, 'blue');
    text-decoration: underline;

    &:before {
      display: none;
    }
  }
</style>
