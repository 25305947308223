import { render, staticRenderFns } from "./InvoiceForm.vue?vue&type=template&id=c9f294ea&scoped=true&"
import script from "./InvoiceForm.vue?vue&type=script&lang=js&"
export * from "./InvoiceForm.vue?vue&type=script&lang=js&"
import style0 from "./InvoiceForm.vue?vue&type=style&index=0&id=c9f294ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9f294ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAlert,VBtn,VCheckbox,VCol,VForm,VIcon,VRow,VSnackbar,VTextField,VTextarea})
