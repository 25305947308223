const redirects = {
  ...(require('../assets/redirects'))
};

function redirect(res, url) {
  res.writeHead(301, { Location: url });
  res.end();
}

export default (req, res, next) => {
  let newUrl = false;
  if (/\/$/.exec(req.url)) {
    newUrl = req.url.replace(/\/$/, '');
  }

  if (req.url.startsWith('//')) {
    newUrl = req.url.replace(/^\/\//, '/');
  }

  if (redirects[newUrl || req.url]) {
    newUrl = redirects[newUrl || req.url];
  }

  const hostname = req.headers.host || '';
  if (hostname.startsWith('www.')) {
    newUrl = `${process.env.BASE_URL}${req.url}`;
  }

  if (newUrl) {
    redirect(res, newUrl);
  } else {
    next();
  }
}
