const middleware = {}

middleware['redirect-www'] = require('../middleware/redirect-www.js')
middleware['redirect-www'] = middleware['redirect-www'].default || middleware['redirect-www']

middleware['redirects'] = require('../middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['setCacheVersion'] = require('../middleware/setCacheVersion.js')
middleware['setCacheVersion'] = middleware['setCacheVersion'].default || middleware['setCacheVersion']

middleware['setLinks'] = require('../middleware/setLinks.js')
middleware['setLinks'] = middleware['setLinks'].default || middleware['setLinks']

middleware['setSettings'] = require('../middleware/setSettings.js')
middleware['setSettings'] = middleware['setSettings'].default || middleware['setSettings']

export default middleware
