<template>
  <section class="button-list" v-editable="blok">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="10" lg="8" xl="6">
          <ul v-if="blok.buttons">
            <li
              v-for="(button, i) in blok.buttons"
              :key="i"
            >
              <app-link
                v-if="is_link(button.to)"
                :to="button.to | url"
              >
                <v-icon v-if="button.icon">icon-{{button.icon}}</v-icon>
                <span>{{ button.text }}</span>
              </app-link>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
	import AppLink from '~/components/AppLink';

  export default {
  	name: 'ButtonList',
    props: ['blok'],
	  components: {AppLink},
  }
</script>

<style lang="scss" scoped>
  @import '~assets/scss/variables';

  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto -1.5rem auto;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      column-count: 2;
      column-gap: 1.5rem;
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      column-count: 3;
      column-gap: 1.5rem;
    }

    @media (min-width: map-get($grid-breakpoints, xl)) {
      column-gap: 3rem;
    }

    > li {
      margin-bottom: 1.5rem;

      > a {
        border: none;
        outline: none;
        border-radius: 2rem;
        background: map-get($grey, 'lighten-4');
        padding: .25rem 2rem;

        text: {
          decoration: none;
          align: center;
        }
        color: $text-color;
        font-weight: $font-weight-bold;

        transition: box-shadow .1s, color .2s;

        display: flex;
        align-items: center;

        > .v-icon {
          font-size: 3rem;
          padding-right: 1rem;
        }

        &:active {
          box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
        }

        &:hover {
          color: map-get($brand-colors, 'blue');

          > .v-icon {
            color: map-get($brand-colors, 'blue');
          }
        }
      }
    }
  }
</style>
