import Vue from 'vue'

import Carousel from '~/components/Carousel'
import Text from '~/components/Text'
import Banner from '~/components/Banner'
import TextImage from '~/components/TextImage/Container'
import ContactForm from '~/components/ContactForm'
import Gallery from '~/components/Gallery'
import Columns from '~/components/Columns'
import Highlights from '~/components/Highlights/Container'
import ButtonList from '~/components/ButtonList'
import LatestNews from '~/components/LatestNews'
import Navigation from '~/components/Navigation'

import FavoritesPage from '~/components/pages/Favorites'
import ProductPage from '~/components/pages/product/Page'
import ProductOverviewPage from '~/components/pages/ProductOverview/Main'
import ProductCategoryOverviewPage from '~/components/pages/ProductCategoryOverview/Main'
import NewsPage from '~/components/pages/News'
import NewsOverviewPage from '~/components/pages/NewsOverview'
import SitemapPage from '~/components/pages/Sitemap'
import DefaultPage from '~/components/Page'

Vue.component('blok-carousel', Carousel)
Vue.component('blok-text', Text)
Vue.component('blok-banner', Banner)
Vue.component('blok-text-image', TextImage)
Vue.component('blok-contact-form', ContactForm)
Vue.component('blok-gallery', Gallery)
Vue.component('blok-columns', Columns)
Vue.component('blok-highlights', Highlights)
Vue.component('blok-button-list', ButtonList)
Vue.component('blok-latest-news', LatestNews)
Vue.component('blok-navigation', Navigation)

Vue.component('blok-page-favorites', FavoritesPage)
Vue.component('blok-page-product-category-overview', ProductCategoryOverviewPage)
Vue.component('blok-page-product-overview', ProductOverviewPage)
Vue.component('blok-page-product', ProductPage)
Vue.component('blok-page-news', NewsPage)
Vue.component('blok-page-news-overview', NewsOverviewPage)
Vue.component('blok-page-sitemap', SitemapPage)
Vue.component('blok-page', DefaultPage)
